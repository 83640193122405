/**
 * 担当者コード変更時の処理
 */
window.changeEmployeeCode = function (target) {
    let targetValue = $(target).val();

    if (isNaN(targetValue)) {
        targetValue = -1;
    }

    let targetOption = ".input-employee-select option[data-code='" + parseInt(targetValue) + "']";

    if ($(targetOption).not('.d-none').length === 1) {
        // 対象コードがあれば、そのリストを選択状態にする
        $(targetOption).prop('selected', true);
    } else {
        // 対象コードがなければ、最初のリストを選択状態にする
        $('.input-employee-select').prop('selectedIndex', 0).change();
    }
}

/**
 * 担当者セレクトボックス変更処理
 */
window.changeEmployee = function () {
    let code = $('.input-employee-select option:selected').data('code');
    // 選択された担当者のコードをセット
    $('.input-employee-code').val(code);
}
