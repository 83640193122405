/**
 * 商品コード変更時の処理
 */
window.changeProductCode = function (target) {
    let targetValue = $(target).val();

    if (isNaN(targetValue)) {
        targetValue = -1;
    }

    let targetOption = ".input-product-select option[data-code='" + parseInt(targetValue) + "']";

    if ($(targetOption).not('.d-none').length === 1) {
        // 対象コードがあれば、そのリストを選択状態にする
        $(targetOption).prop('selected', true);
    }

    //select2チェンジイベント
    $('.select2_search').trigger('change');
}

/**
 * 商品セレクトボックス変更処理
 */
window.changeProduct = function () {
    let code = $('.input-product-select option:selected').data('code');

    function zeroPad(num, places) {
        var zero = places - num.toString().length + 1;
        return Array(+(zero > 0 && zero)).join("0") + num;
    }

    if (code) {
        code = zeroPad(code, 8)
    }
    // 選択された商品のコードをセット
    $('.input-product-code').val(code);
}
